.nav-menu {
  background-color: #ffffff;
  max-height: 50px;
  align-items: center;
  justify-content: center;
  letter-spacing: 0;
}

.dropdown{
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown hr{
  height: 2px;
  background: rgb(255, 116, 139) !important;
}
.nav-menu-scrolled{
  max-height: 65px;
  background-color: rgb(255, 255, 255);
}

.dropdown-scrolled{
  min-height: 65px;
}

.all-menu{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 22px;
}
.menu-hover{
  position: absolute;
  z-index: 99999;
  transition: all 300ms ease-in-out;
  top: 50px;
  background-color: #fff;
  border-top: none;
  border-radius: 10px;
  color: black;
  font-weight: bold;
  width: 95vw;
  left: 50%; /* Set left to 50% */
  transform: translateX(-50%); /* Translate back by half of its own width */
  padding: 20px 60px;
  padding-top: 40px;
  border: 1px solid #f34b7f;
  border-top: none;
  box-shadow: 10px 10px 10px #f34b8080;
}
.menu-hover a{
  padding-bottom: 10px;
}

.menu-scrolled{
  top: 65px;
}
.nav-menu a{
    transition: all ease-in-out 200ms;
}
.short-logo {
  margin-top: 0;
  width: 30px;
  padding: 4px;
}

@media screen and (max-width:1370px){
  .nav-menu{
    letter-spacing: -0.5px;
  }
  .all-menu{
    font-size: 11px;
  }
}
@media screen and (max-width:1280px){
  .nav-menu{
    letter-spacing: -.8px;
  }
  .all-menu{
    font-size: 8px;
    justify-content: left;
    margin-left: 10px;
  }
}

@media screen and (max-width:640px){

  .menu-hover{
    position: relative;
    z-index: 99999;
    transition: all 300ms ease-in-out;
    top: 0;
    right: 0;
    background-color: white;
    color: black;
  }
}



