.product {
  width: 100%;
  height: 450px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.list-container .product {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 240px;
  margin: 0;
}
.product img {
  height: 250px;
  margin: 0 auto;
  transition: scale 800ms ease-in-out;
  /* transition: all ease-in-out 400ms; */
}
.product-details {
  padding: 0 10px;
  height: 180px;
}

.list-container .product-details {
  text-align: left;
  position: relative;
  width: 75%;
}

.list-container .img-container {
  width: 20%;
  height: 130%;
}


.img-container {
  width: 100%;
  height: 100%;
  position: relative;
  margin-bottom: 10px;
  overflow: hidden;
  border-radius: 20px;
}

.img-div {
  position: relative;
  display: flex;
  background: #fff;
  height: 300px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.batch{
  width: 100px;
  height: 100px !important;
}
/* .img-div::after {
  content: "";
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
  background-color: white;
  display: flex;
  opacity: 0;
  transition: all ease-in-out 200ms;
}

.img-container:hover .img-div::after {
  opacity: 60%;
} */
.img-container:hover img {
  scale: 1.3;
  /* transform: rotateY(360deg); */
}

.details-btn {
  background: white;
  color: black;
  padding: 2px 10px;
  box-shadow: 2px 2px 0px black;
  position: absolute;
  top: 50%;
  right: 50%;
  translate: 50% -50%;
  width: 110px;
  display: none;
  transition: all ease-in-out 200ms;
  font-size: 10px;
  line-height: 25px;
}
.details-btn:hover {
  background: black;
  color: white;
}
.add-btn{
  bottom: 0;
}
.add-btn,
.list-btn {
  color: white;
  padding: 6px;
  width: 100%;
  position: absolute;
  display: none;
  transition: all ease-in-out 200ms;
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
}
.list-container .list-btn {
  width: 20%;
  display: flex;
  justify-content: center;
}

.img-container:hover .details-btn {
  display: flex;
  justify-content: center;
}
.img-container:hover .add-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.list-container .img-container:hover .add-btn {
  display: none;
}

.limit-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2 ;
  -webkit-box-orient: vertical;
}

@media screen and (max-width: 992px) {
  .product {
    width: 80%;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 640px) {
  .product {
    width: 90%;
    text-align: left;
    font-size: 14px;
    height: 350px;
  }
  .product img {
    height: 180px;
    margin-bottom: 50px;
  }

  .product-details {
    padding: 0;
    height: 100px;
  }
  
  .add-btn{
    padding: 6px;
    display: block;
    background: #f34b7f;
    color: white;
    /* border: 1px solid black; */
  }
  .img-container{
    height: 80%;
    width: 100%;
  }

  .img-container:hover .details-btn {
    display: none;
  }
  .batch{
    width: 80px;
    height: 80px !important;
  }


}
