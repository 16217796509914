.most-favorite{
    position: relative;
    margin-top: 30px;
    background: #fceef2;
}

.featured-collections::after{
    top: 100%;
}
@media screen and (max-width: 640px) {
    .most-favorite{
        margin-top: 0;
    }
}