.featured {
  margin-top: 30px;
  padding-top: 50px;
  padding-bottom: 50px;
  gap: 20px;
  display: flex;
}

.featured a {
  background-position: center;
  background-size: cover;
  width: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 20px;
}

.featured a:nth-child(1):after {
  content: "";
  background-color: black;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  opacity: 0;
  display: flex;
  transition: opacity 400ms ease-in-out;
}

.featured a:hover::after {
  opacity: 0.3;
  color: #ff81a9;
}

.featured  img {
  display: block;
  width: 100%;
  transition: scale 400ms ease-in-out;
}
.featured a:hover img{
  scale: 1.1;
}

@media screen and (max-width: 992px) {
  .featured {
    width: 100%;
    padding: 20px;
    display: flex;
    gap: 10px;
    justify-content: center;
    margin: 0 auto;
  }
  /* .featured img{
    width: 140px;
    height: 140px;
    margin: 0 auto;
  } */
  
}

@media screen and (max-width: 640px) {
  .featured {
    margin-top: 20px;
    width: 100%;
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    justify-content: center;
    margin: 0 auto;
  }
  /* .featured img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0 auto;
  }
   */
  .featured p {
    text-decoration: underline;
  }
  .featured  img {
    width: 95%;
    margin: 0 auto;
  }

}
